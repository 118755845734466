@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap");

@import "./variables.scss";
@import "./global.scss";
@import "./themes.scss";

@import "components/Progressbar.scss";
@import "views/Intro.scss";
@import "views/Questions.scss";
@import "views/Results.scss";
