body {
  background: url(/assets/images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 95%;

  background: rgba($white, 0.8);
  border-radius: 15px;
  @include media-breakpoint-up(sm) {
    height: 700px;
    width: 1000px;
  }

  &.results {
    width: 100%;
    height: 100%;
    top: auto;
    left: auto;
  }

  .logo {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .headline {
    width: 80%;
    @include media-breakpoint-up(sm) {
      width: 380px;
    }

    &.mini {
      width: 60%;
      @include media-breakpoint-up(sm) {
        width: 220px;
      }
    }
  }

  .container-narrow {
    @include media-breakpoint-up(sm) {
      width: 50%;
      margin: 0 auto;
    }
  }

  .rc-slider-mark-text {
    content: "";
    color: transparent;
    width: 60px;
    height: 60px;
    top: 10px;
    position: absolute;
    display: inline-block;
    strong {
      display: none;
    }
  }

  .rc-slider-mark-text:nth-of-type(1) {
    background: url("https://escapebox.si/temp/ny23/sound1.svg") no-repeat;
    background-size: 100%;
  }

  .rc-slider-mark-text:nth-of-type(2) {
    background: url("https://escapebox.si/temp/ny23/sound2.svg") no-repeat;
    background-size: 100%;
  }

  .rc-slider-mark-text:nth-of-type(3) {
    background: url("https://escapebox.si/temp/ny23/sound3.svg") no-repeat;
    background-size: 100%;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $turq;
    box-shadow: 0 0 0 5px rgba($turq, 0.6);
  }

  .rc-slider-handle {
    width: 20px;
    height: 20px;
    background-color: $turq;
    margin-top: -8px;
  }

  .rc-slider {
    @include media-breakpoint-down(sm) {
      width: 70%;
      margin: 0 auto;
    }
  }
}

.main-button {
  background: linear-gradient(270deg, #46d2cf 0%, #168885 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  color: $white;
  text-transform: uppercase;
  font-weight: 800;
  &:hover {
    color: $white;
    background: linear-gradient(220deg, #46d2cf 0%, #168885 100%);
  }

  &:active,
  &:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    color: $white !important;
  }
}

.form-control {
  background: #ffffff;
  border-radius: 6px;
  border: 0;
  height: 62px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-question {
  border: 0 !important;
  img {
    width: 96px;
  }

  transition: all 0.2s ease-in-out;
  &:hover,
  &:active {
    opacity: 0.8;
    transform: scale(1.05);
  }
}

.quiz-content {
  width: 350px;
  margin: 0 auto;
}

.small {
  font-size: 12px;
}

.button-bottom {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  .btn {
    font-size: 1rem;
    padding: 0.25rem 2rem;
    width: 200px;
  }
}

.result-card {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  @include media-breakpoint-down(sm) {
    width: 300px;
  }
}

.preloader {
  border-radius: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  z-index: 10;
}

.btn.disabled {
  color: $white;
  opacity: 0.5;
}
