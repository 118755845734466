.step-progress-bar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 200px;

  /*   &::after {
    background: #85d0ce;
    bottom: 5.75px;
    content: " ";
    height: 0.7px;
    position: absolute;
    width: 100%;
    z-index: 1;
  } */
  .stepBullet {
    background: #d9d9d9;

    height: 10px;
    width: 33.3%;
    list-style: none;
    padding-left: 0;
    z-index: 2;

    &.done {
      background: #d71b5a;
    }

    &.active {
      background: rgba(#d71b5a, 0.2);
      //box-shadow: 0px 0px 8px 3px rgba(32, 131, 128, 0.4);
    }
  }
}
