.results {
  h1 {
    span {
      display: block;
    }
  }
  .name {
    position: absolute;
    top: 3.5%;
    left: 11%;
    font-family: "Caveat", cursive;
    font-size: 2rem;
    text-transform: capitalize;
  }
}
