.quiz-intro {
  background-image: url(/assets/images/bg.jpg);

  @include media-breakpoint-down(sm) {
    background-image: url(/assets/images/bg-mobile.jpg);
  }

  .h1-welcome {
    span {
      display: block;
      font-size: 14px;
    }
  }
  .usps {
    p {
      @include media-breakpoint-down(sm) {
        font-size: 0.7rem;
      }
    }

    img {
      @include media-breakpoint-down(sm) {
        height: 35px;
      }
    }
  }
}
